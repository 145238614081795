<template>
    <div class="myHomePage">
        <!-- CounterInquiryOrder -->
        <div class="changePass" v-if="flagChange">
            <div class="changePassBox">
                <div class="changePassTitle">修改密码<div @click="flagChange = false" class="changeCloese">X</div>
                </div>

                <div class="changePassInput">
                    <!-- <input type="text" v-model="changeUserName" placeholder="请输入用户名">
                    <input type="text" v-model="changeoldPass" placeholder="请输入旧密码">
                    <input type="text" v-model="changeNewPass" placeholder="请输入新密码">
                    <input type="text" v-model="changeNewPass1" placeholder="请输入新密码"> -->
                    <!-- <el-input v-model="changeUserName" placeholder="请输入用户名"></el-input> -->
                    <el-input v-model="changeoldPass" placeholder="请输入旧密码" show-password></el-input>
                    <el-input v-model="changeNewPass" placeholder="请输入新密码" show-password></el-input>
                    <el-input v-model="changeNewPass1" placeholder="请确认新密码" show-password></el-input>
                </div>
                <div class="changePassBtn" @click="handleXiuGai">修改密码</div>
            </div>
        </div>
        <div class="myHomePage_Title"><span>{{ phone }}，欢迎您！</span></div>
        <div class="myHomePage_Card">
            <div class="card_1 card">
                <div class="cardTitle"><span>侨链国际，您的贸易管家</span></div>
                <div class="card_Information">
                    <ul>
                        <li>
                            <div class="img">
                                <img src="../../assets/BookingSpace/touxiang.png" alt="">
                            </div>
                            <span>{{ phone }}</span>
                        </li>
                        <li>
                            <div class="imgs">
                                <img src="../../assets/homePage/phone-telephone.png" alt="">
                            </div>
                            <p>{{ authInfos.tel }}</p>
                        </li>
                        <li>
                            <div class="imgs">

                                <img src="../../assets/myHomePage/mail.png" alt="">
                            </div>
                            <p>{{ authInfos.email }}</p>
                        </li>
                        <li>
                            <div class="imgs">

                                <img src="../../assets/myHomePage/floor.png" alt="">
                            </div>
                            <p>{{ authInfos.enterpriseName }}</p>
                        </li>
                    </ul>
                </div>
                <div class="card_Botton">
                    <div class="botton_Btn" @click="handleAuthen"><span>认证信息</span></div>
                    <div class="botton_Btn" @click="handlechangePassword"><span>修改密码</span></div>
                    <div class="botton_Btn" @click="handleLogOut"><span>退出登录</span></div>
                </div>
            </div>
            <div class="card_2 card">
                <div class="cardTitle"><span>待处理消息</span><span>{{ memberMessageList.length }}</span></div>
                <div class="card_2_Message">
                    <div class="message_1">
                        <p v-for="(item, index) in memberMessageList" :key="index">{{ item.content }}</p>
                    </div>
                    <div class="message_2" v-show="memberMessageList" @click="handlesee"><span>立即查看</span><img
                            src="../../assets/myHomePage/share.png" alt=""></div>
                </div>
            </div>
        </div>
        <div class="myHomePage_Title_Button">
            <div class="btn_1" @click="handlereserve">
                <div class="img"><img src="../../assets/myHomePage/container_bg.png" alt=""></div><span>集装箱预定</span>
            </div>
            <div class="btn_1" @click="handleinquiry">
                <div class="img"><img src="../../assets/myHomePage/Reserve.png" alt=""></div><span>询柜问价</span>
            </div>
            <div class="btn_1" @click="handlehistoricalOrders">
                <div class="img"><img src="../../assets/myHomePage/419.png" alt=""></div><span>历史订单</span>
            </div>
            <div class="btn_1" @click="handleMessg">
                <div class="img"><img src="../../assets/myHomePage/message.png" alt=""></div>
                <span>消息中心</span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex'
import { baseUrl } from '../../tools/baseUrl';
export default {
    name: "MyHomePage",
    data() {
        return {
            phone: '',
            authInfos: {},  //认证信息
            memberMessageList: [],
            flagChange: false,

            changeUserName: '',//
            changeoldPass: '',
            changeNewPass: '',
            changeNewPass1: '',
        }
    },

    watch: {},
    created() {
        var token = sessionStorage.getItem("token")
        this.phone = localStorage.getItem("phone");
        var authen = JSON.parse(localStorage.getItem("authInfo"));
        if (authen) {
            this.authInfos = JSON.parse(localStorage.getItem("authInfo"));
        }

        axios.get(`${baseUrl}/memberMessage/list`, {
            params: { pageNo: 1, isRead: 0 },
            //请求头配置  
            headers: { 'authorization': token }
        }).then(res => {
            // console.log(res.data.result.records)
            this.memberMessageList = res.data.result.records;
            let reg = new RegExp('<[^<>]+>', 'g');
            var textList = this.memberMessageList;
            textList.forEach((v, i) => {
                this.memberMessageList[i].content = v.content.replace(reg, "");
                // console.log(this.memberMessageList);
            })
        });

        if (token) {
            axios.get(`${baseUrl}/authen/view`, {
                headers: {
                    'authorization': token
                }
            }).then(res => {
                // console.log(res)
                if (res.data.success == true) {
                    this.authInfos = res.data.result
                }
            })
        }

    },
    methods: {
        handleAuthen() {
            this.$router.push("/Authen")
        },
        handlechangePassword() {
            this.flagChange = true
        },
        handleXiuGai() { //确认修改
            var token = sessionStorage.getItem("token")
            // if (this.changeUserName == '') {
            //     this.$message({
            //         showClose: true,
            //         message: '请输入正确用户名',
            //         type: 'error'
            //     });
            //     return false
            // } else
            if (this.changeoldPass == '') {
                this.$message({
                    showClose: true,
                    message: '请输入正确旧密码',
                    type: 'error'
                });
                return false
            } else if (this.changeNewPass !== this.changeNewPass1) {
                this.$message({
                    showClose: true,
                    message: '两次输入密码不一致',
                    type: 'error'
                });
                return false
            } else {
                axios.post(`${baseUrl}/member/modifyPwd`, {
                    // mobile: this.changeUserName,
                    oldPassword: this.changeoldPass,
                    password: this.changeNewPass
                }, {
                    headers: {
                        'authorization': token,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
                    .then(res => {
                        // console.log(res)
                        if (res.data.code == 200 && res.data.message == '修改成功') {
                            this.$message({
                                showClose: true,
                                message: '修改成功',
                                type: 'success'
                            });
                            this.flagChange = false
                        } else {
                            this.$message({
                                showClose: true,
                                message: '用户名或密码错误',
                                type: 'error'
                            });
                            return false
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    })
            }
        },
        handleLogOut() { //退出登录
            window.sessionStorage.clear();
            window.localStorage.clear();
            this.$store.commit("set_token", '');
            this.$store.commit("set_authInfo", '');
            this.$router.replace({ path: '/' });

            location.reload();


        },
        handlesee() {  //立即查看
            this.$router.push("/PersonalCenter/MessageManagement")
        },
        handlereserve() {  //集装箱预定
            this.$router.push("/PersonalCenter/CounterInquiryOrder")
        },
        handleinquiry() { //询柜订单
            this.$router.push("/PersonalCenter/CounterInquiryOrder")
        },
        handlehistoricalOrders() {//历史订单
            this.$router.push("/PersonalCenter/RentBoxs")
        },
        handleMessg() {//消息中心
            this.$router.push("/PersonalCenter/MessageManagement")
        }
    },
}
</script>

<style lang="scss" scoped>
.myHomePage {
    // position: relative;
    // max-width: 1526px;
    // min-height: 690px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    padding: 24px 48px 32px;

    margin: 0 auto;

    .changePass {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        z-index: 100;

        .changePassBox {
            position: absolute;
            left: 50%;
            top: 100px;
            transform: translateX(-50%);
            width: 430px;
            // height: 378px;
            background: #FFFFFF;
            border-radius: 8px 8px 8px 8px;
            padding-bottom: 20px;

            .changePassTitle {
                position: relative;
                font-size: 30px;
                font-weight: bold;
                color: #333333;
                text-align: center;
                margin-top: 30px;

                .changeCloese {
                    position: absolute;
                    right: 20px;
                    top: -20px;
                    font-size: 16px;
                    cursor: pointer;
                }


            }



            .changePassInput {
                width: 370px;
                margin: 30px auto 0;

                ::v-deep .el-input__inner {
                    width: 100%;
                    height: 40px;
                    margin-top: 16px;
                    // border-color: #333333;
                }

                ::v-deep .el-input__suffix {
                    height: 40px;
                    top: 15px;
                }
            }

            .changePassBtn {
                width: 370px;
                height: 40px;
                background: #1890FF;
                box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
                border-radius: 4px 4px 4px 4px;
                text-align: center;
                line-height: 40px;
                font-size: 16px;
                font-weight: 500;
                color: #FFFFFF;
                margin: 16px auto 0;
                cursor: pointer;
            }
        }
    }

    .myHomePage_Title {
        height: 14px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
        margin-top: 24px;
    }

    .myHomePage_Card {
        position: relative;
        display: flex;
        // max-width: 1526px;
        margin-top: 32px;

        justify-content: space-between;
        .card {
            width: 700px;
            height: 264px;

        }

        .card_1 {
            position: relative;
            // float: left;
            background: #1890FF;
            box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
            border-radius: 4px 4px 4px 4px;
            padding: 24px 24px 10px;

            .cardTitle {
                font-size: 16px;
                font-weight: 400;
                color: #FFFFFF;
                height: 16px;
                line-height: 16px;
            }

            .card_Information {
                margin-top: 32px;

                ul {
                    li {
                        display: flex;
                        align-items: center;
                        margin-top: 8px;
                        height: 18px;

                        .img {
                            width: 36px;
                            height: 36px;
                            background: #FFFFFF;
                            border-radius: 50%;
                            border: 1px solid #707070;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .imgs {
                            width: 18px;
                            height: 18px;
                            background: rgba(255, 255, 255, 0.01);

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        p {
                            font-size: 12px;
                            font-weight: 400;
                            color: #FFFFFF;
                            margin-left: 8px;
                        }

                        span {
                            margin-left: 16px;
                            font-size: 12px;
                            font-weight: 400;
                            color: #FFFFFF;
                        }
                    }

                    li:nth-child(1) {
                        height: 36px;
                        margin-top: 16px;
                    }
                }
            }

            .card_Botton {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-top: 36px;

                .botton_Btn {
                    // width: 205px;
                    flex: 1;
                    height: 28px;
                    background: #FFFFFF;
                    box-shadow: 0px 3px 6px 1px #007AFF;
                    border-radius: 4px 4px 4px 4px;
                    text-align: center;
                    line-height: 28px;
                    margin-left: 19px;
                    cursor: pointer;
                }

                .botton_Btn:nth-child(1) {
                    margin-left: 0;
                }
            }

        }

        .card_1::before {
            position: absolute;
            top: 56px;
            left: 0;
            content: "";
            width: 100%;
            height: 1px;
            background: #F5F5F5;
        }

        .card_2 {
            float: right;
            background: #FFFFFF;
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
            border-radius: 4px 4px 4px 4px;
            margin-left: 30px;

            .cardTitle {
                position: relative;
                height: 56px;
                line-height: 56px;
                padding-left: 24px;
                font-size: 16px;
                font-weight: 400;
                color: #333333;

                span:nth-child(2) {
                    font-size: 16px;
                    font-weight: 400;
                    color: #D4252C;
                }
            }

            .cardTitle::before {
                content: "";
                position: absolute;
                left: 0;
                top: 56px;
                height: 1px;
                width: 100%;
                background-color: #999999;
            }

            .card_2_Message {
                display: flex;
                justify-content: space-between;
                width: 100%;
                height: 208px;
                padding: 26px 24px 0 24px;

                .message_1 {
                    // flex: 1;
                    width: 344px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;

                    p {
                        margin-top: 8px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .message_2 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 110px;
                    height: 28px;
                    background: #E9F4FF;
                    border-radius: 4px 4px 4px 4px;
                    text-align: center;
                    cursor: pointer;

                    span {
                        line-height: 28px;
                    }

                    img {
                        display: block;
                        width: 14px;
                        height: 14px;
                        margin-left: 8px;
                    }
                }
            }
        }
    }

    .myHomePage_Card::before {
        position: absolute;
        bottom: -32px;
        content: "";
        width: 100%;
        height: 1px;
        background-color: #999999;
    }

    .myHomePage_Title_Button {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 64px;

        .btn_1 {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            // max-width: 315px;
            height: 40px;
            background: #1890FF;
            box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
            border-radius: 4px 4px 4px 4px;
            margin-left: 56px;
            cursor: pointer;

            .img {
                width: 16px;
                height: 16px;
                background: #1890FF;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            span {
                margin-left: 8px;
                font-size: 16px;
                font-weight: 400;
                color: #FFFFFF;
            }

        }

        .btn_1:nth-child(1) {
            margin-left: 0;
        }
    }
}
</style>